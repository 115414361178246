import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Hero from '../components/hero';
import MainContent from '../components/mainContent';

const PageTemplate = ({ data }) => (
  <Layout>
    <SEO title={data.strapi.page.title}></SEO>
    <Hero options={data.strapi.page.hero}></Hero>
    <MainContent options={data.strapi}></MainContent>
  </Layout>
);

export default PageTemplate;

export const query = graphql`
  query PageTemplate($id: ID!) {
    strapi {
      newsletterTags {
        id
        tag
      }
      page(id: $id) {
        id
        title
        hero {
          id
          image {
            url
          }
          heading
          stand_first
          cta {
            link
            internal_link
            position
            text
          }
        }
        main {
          __typename
          ... on Strapi_ComponentGenericSimpleText {
            id
            type
            text
          }
          ... on Strapi_ComponentGenericMediaObject {
            id
            heading
            body_rich_text
            media_alignment: alignment
            image_desktop {
              id
              url
            }
            image_mobile {
              id
              url
            }
            background_theme
            date_posted
          }
          ... on Strapi_ComponentGenericHeadingComponent {
            id
            heading
            stand_first
            stand_first_rich_text
            heading_alignment: alignment
            theme
            padding_bottom
          }
          ... on Strapi_ComponentGenericInfoCardSection {
            id
            Info_card {
              type
              info_heading
              info_content
            }
          }
          ... on Strapi_ComponentGenericGoogleMap {
            id
            full_width
          }
          ... on Strapi_ComponentGenericRichTextSection {
            id
            direction
            rich_text {
              alignment
              content
            }
          }
          ... on Strapi_ComponentGenericNewsletterListSection {
            id
            newsletter_items {
              newsletter_date
              newsletter_title
              newsletter_file {
                name
                url
                size
                updatedAt
              }
              newsletter_tags {
                tag
              }
            }
          }
        }
      }
    }
  }
`;
